import React from "react";
import { graphql } from "gatsby";

import Layout from '../components/layout';
import Post from '../components/Post';

export const AllBlogsQuery = graphql`
	query blogListQuery($skip: Int!, $limit: Int!) {
		allMarkdownRemark( 
			sort: { fields: [frontmatter___date], order: ASC }
      		limit: $limit
      		skip: $skip
		) {
			edges {
				node {
					frontmatter {
						date(formatString: "DD/MM/YYYY")
						title
						author
						description
						path
						featuredImage {
							childImageSharp {
								fluid(maxWidth: 300) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`

const BlogList = ({data}) => (
  <Layout title="Articles">
	{console.log(data)}
    <h1>Blog</h1>
	{
		data.allMarkdownRemark.edges.map(post => {
			const {date, title, author, description, path, featuredImage} = post.node.frontmatter;
			let featuredImgFluid = featuredImage.childImageSharp.fluid;

			return (
				<Post
					date={date}
					title={title}
					author={author}
					description={description}
					path={path}
					key={`${date}__${title}`}
					featuredImage={featuredImgFluid}
				/>
			)
		})
	}
  </Layout>
);

export default BlogList;