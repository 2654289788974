import React from 'react';
import {Link} from 'gatsby';
import Img from "gatsby-image"

const Post = ({title, author, date, description, path, featuredImage}) => (
	<Link to={ path } className="post">
		<Img fluid={featuredImage} />
		<div className="infos">
			<h3 className="post-title">{title}</h3>
			<div className="date">{date}</div>
		</div>
	</Link>
);

export default Post;